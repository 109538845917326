// App main
import 'unfonts.css';
import 'virtual:svg-icons-register';
import 'swiper/css';
import '@/css/app.pcss';
import $ from 'jquery';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import Swiper, {Navigation, Pagination} from 'swiper';
import barba from '@barba/core';
import gsap from 'gsap';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';


Alpine.plugin(collapse);

Swiper.use([Navigation, Pagination]);

let checkForm = function () {
    let val = $('#inquiry option:checked').val();
    console.log(val);
    if (val == 'generalMessage') {
        $('#productNumber').hide();
        $('#phoneNumber').hide();
        $('#orderNumber').hide();
    } else if (val == 'technicalSupport' || val == 'askForSamples' || val == 'fileRequest') {
        $('#productNumber').show();
        $('#phoneNumber').hide();
        $('#orderNumber').hide();
    } else if (val == 'quote') {
        $('#productNumber').show();
        $('#phoneNumber').show();
        $('#orderNumber').hide();
    } else if (val == 'deliveryStatus') {
        $('#productNumber').hide();
        $('#phoneNumber').hide();
        $('#orderNumber').show();
    }
};
jQuery(() => {
    checkForm();
});
$(document).on('change', '#inquiry', function () {
    console.log('check');
    checkForm();
});

$(function () {
    checkForm();
    if ($(window).width() > 768) {
        $('nav .inner-link').click(function () {
            $('.customLink.active').removeClass('active');
            let $this = $(this);
            if ($this.hasClass('child')) {
                $this = $(this).closest('.parent');
            }
            // GSAP animate to clicked menu item

            gsap.to('#menu .menu-hover-line', 0.1, {
                css: {
                    width: $this.outerWidth() + 'px',
                    left: $this.position().left + 'px',
                    opacity: 1
                },
                overwrite: true,
                onComplete: function () {
                    setTimeout(() => {
                        $this.addClass('active');
                    }, 100);
                }
            });
        });
        $('#homepageLink').click(function () {
            let $this = $('#inner-link-Home');
            if (this.length > 1) {
                gsap.to('#menu .menu-hover-line', 0.1, {
                    css: {
                        width: $this.outerWidth() + 'px',
                        left: $this.position().left + 'px',
                        opacity: 1
                    },
                    overwrite: true,
                    onComplete: function () {
                        setTimeout(() => {
                            $this.addClass('active');
                        }, 100);
                    }
                });
            } else {
                gsap.to('#menu .menu-hover-line', 0.1, {
                    css: {
                        opacity: 0
                    },
                    overwrite: true,
                    onComplete: function () {
                        setTimeout(() => {
                            $this.addClass('active');
                        }, 100);
                    }
                });
            }

            $('.inner-link.active').removeClass('active');
            $('#inner-link-Home').addClass('active');
        });
    }
});

// adds sliding underline
$('#menu').append(
    '<span class="invisible md:visible menu-hover-line absolute w-0 -bottom-2 md:bottom-0 h-[1px] md:h-[3px] bg-white shadow-header transition-all ease-linear duration-100 opacity-0"></span>'
);

let links = document.querySelectorAll('a[href]');
let cbk = function (e) {
    if (e.currentTarget.href === window.location.href) {
        e.preventDefault();
        e.stopPropagation();
    }
};

for (let i = 0; i < links.length; i++) {
    links[i].addEventListener('click', cbk);
}


const main = async () => {

};

const newsletterSignup = (data) => {
    return new Promise((resolve, reject) => {
        let url = 'https://crayonano.us11.list-manage.com/subscribe/post-json?u=c46103382d988eeb11056aded&id=3c6b23f7b3&f_id=007b9ee0f0&c=?';
        let body = 'tags=' + data.tag;
        body += '&EMAIL=' + data.email;
        body += '&NAME=' + data.name;
        body += '&COMPANY=' + data.company;
        if (data.investor) {
            body += '&group[35032][1]=true';
        }
        if (data.news) {
            body += '&group[35032][2]=true';
        }
        $.post({
            url: url,
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            data: body,
            success: (res) => {
                if (res.result === 'error') {
                    console.log('error', res);
                    reject(new Error('Please enter a valid e-mail address'));
                } else if (res.result === 'success') {
                    console.log('success', res);
                    resolve(res);
                }
            },
            error: (err) => {
                reject(err);
            }
        })
    });
}

// Execute async function
main().then(() => {
    barba.init({
        preventRunning: true,
        timeout: 5000,
        prevent: ({el}) => el.classList && el.classList.contains('barba-prevent') || el.href.includes('.pdf'),
        transitions: [
            {
                sync: true,
                name: 'opacity-transition',
                leave({current}) {
                    gsap.to(current.container, {
                        duration: 0.5,
                        opacity: 0
                    });
                },
                enter({next}) {
                    gsap.from(next.container, {
                        opacity: 0
                    });
                },
                beforeEnter() {
                },
                afterEnter() {
                    $('html, body').animate({scrollTop: 0}, 'slow');
                    // $('iframe').iframeResize([{log: true}])
                },
                after() {
                    // console.log('after enter');
                    checkForm();

                }
            }
        ],
        views: [
            {
                namespace: 'header',
                beforeEnter() {
                    update();
                }
            }
        ]
    });

    window["app"] = {
        hero() {
            return {
                playing: false,
                el: this.$root.querySelector('video'),
                init() {
                    if (this.el) {
                        this.playing = !this.el.paused;
                    }
                },
                toggleVideo() {
                    this.playing = !this.playing;
                    if (this.playing) {
                        this.el.play();
                    } else {
                        this.el.pause();
                    }
                }
            };
        },
        fileList(slug = '') {
            return {
                files: [],
                pagination: {},
                category: slug,
                sorts: [
                    {value: '', label: 'Date, newest'},
                    {value: 'created:desc', label: 'Date, oldest'},
                    {value: 'title:asc', label: 'Title A-Z'},
                    {value: 'title:desc', label: 'Title Z-A'}
                ],
                currentSort: '',
                query: '',
                init() {
                    this.currentSort = this.sorts[0];
                    this.search();
                    this.$watch('currentSort', () => {
                        this.search();
                    })
                },
                search() {
                    this.getFiles(this.makeUrl());
                },
                makeUrl() {
                    let url = '/api/files';
                    const parts = [];
                    if (this.category) {
                        parts.push('category=' + this.category);
                    }
                    if (this.query) {
                        parts.push('query=' + this.query);
                    }
                    if (this.currentSort.value) {
                        parts.push('sort=' + this.currentSort.value);
                    }

                    return url + '?' + parts.join('&');
                },
                navigate(slug, ev) {
                    ev.preventDefault();
                    history.pushState(null, null, '/files/' + slug);
                    this.category = slug;
                    this.search();
                },
                getUrls(type = 'prev', num = 3) {
                    const tmp = [];

                    switch (type) {
                        case 'prev':
                        default:
                            const start = Math.max(this.pagination.currentPage - num, 1);
                            for (let i = start; i < this.pagination.currentPage; i++) {
                                tmp.push(i);
                            }
                            break;
                        case 'next':
                            const end = Math.min(this.pagination.currentPage + num, this.pagination.pages);
                            for (let i = this.pagination.currentPage + 1; i <= end; i++) {
                                tmp.push(i);
                            }
                            break;
                    }

                    return tmp;
                },
                async getFiles(url) {
                    const json = await (await fetch(url)).json()
                    this.files = json.files;
                    this.pagination = json.pagination;
                },
                goToPage(num) {
                    const url = this.pagination.urlFormat.replace('{num}', num);
                    this.getFiles(url);
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            };
        },
        video(url) {
            return {
                playing: false,
                url: url,
                iframeUrl: '',
                init() {
                    const regex = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
                    const match = this.url.match(regex);
                    const id = match[1];
                    this.iframeUrl = `<iframe src="https://www.youtube.com/embed/${id}?autoplay=1&amp;rel=0" allowfullscreen="" allow="autoplay;" class="w-full aspect-video"></iframe>`;
                },
                playVideo() {
                    this.playing = true;
                }
            };
        },
        menu() {
            return {
                showMenu: false,
                searchOpen: false,
                languageOpen: false,
                languageSelect: false
            };
        },
        newsletter() {
            return {
                error: '',
                email: '',
                name: '',
                company: '',
                investor: true,
                news: true,
                sent: false,
                submit() {
                    console.log('test');
                    newsletterSignup({
                        email: this.email,
                        name: this.name,
                        company: this.company,
                        investor: this.investor,
                        news: this.news,
                        tag: '9273308'
                    })
                        .then(() => {
                            this.sent = true;
                        })
                        .catch(err => {
                            this.error = err.message;
                            console.error(err);
                        });
                }
            };
        },
        contact() {
            return {
                error: '',
                email: '',
                name: '',
                company: '',
                investor: true,
                news: true,
                csrf: '',
                init() {
                    fetch('/actions/blitz/csrf/json')
                        .then(res => res.json())
                        .then(res => {
                            console.log(res);
                            document.querySelector<HTMLInputElement>(
                                '[name=' + res.param + ']'
                            ).value = res.token;
                        });
                },
                submit() {

                    if (this.investor || this.news) {
                        newsletterSignup({
                            email: this.email,
                            name: this.name,
                            company: this.company,
                            investor: this.investor,
                            news: this.news,
                            tag: '9273312'
                        })
                            .then(res => {
                                console.log(res)
                                let el = document.getElementById('contact-block');
                                let rect = el.getBoundingClientRect();
                                let offset = rect.top + window.scrollY;

                                scroll({
                                    top: offset,
                                    behavior: 'smooth'
                                });
                            })
                            .catch(err => {
                                this.error = 'There was an error subscribing to the newsletter, please try again later.';
                                console.error(err);
                            });
                    }
                    const redirect = $("input[name=redirect]", $('#contact-form'))?.val() || null;
                    $.post({
                        url: '/',
                        dataType: 'json',
                        data: $('#contact-form').serialize(),
                        success: () => {
                            if (redirect) {
                                window.location.replace(redirect);
                                return true;
                            }
                            $('#message-block').show();
                            $('#form-block').hide();
                        },
                        error: function (jqXHR) {
                            // The response body will be an object containing the following keys:
                            // - `message` – A high level message for the response
                            // - `submission` – An object containing data from the attempted submission
                            // - `errors` – An object containing validation errors from the submission, indexed by attribute name
                            console.log(jqXHR.responseJSON.errors);
                        }
                    });
                }
            };
        }
    };

    function update() {
        document.querySelectorAll('.js--lang-switch').forEach(elm => {
            elm.addEventListener('click', () => {
                const date = new Date();
                date.setTime(date.getTime() + 31 * 24 * 60 * 60 * 1000);
                document.cookie =
                    'GeoMateRedirectOverride=true;expires=' + date.toUTCString() + ';path=/';
            });
        });

        document.querySelectorAll<HTMLElement>('.swiper').forEach(elm => {
            new Swiper(elm, {
                spaceBetween: 32,
                slidesPerView: 'auto',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass:
                        'w-8 h-1 bg-white opacity-50 mx-1 transition-all duration-300 cursor-pointer',
                    bulletActiveClass: '!opacity-100'
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            });
        });

        document.querySelectorAll<HTMLElement>('.swiper-tabs').forEach(elm => {
            const tabNames: string[] = [];

            const swiperTabNames = document.querySelectorAll('.swiper-tab-names span');
            for (const swiperTabName of swiperTabNames) {
                if (swiperTabName.textContent) {
                    tabNames.push(swiperTabName.textContent);
                }
            }

            const swiper = new Swiper(elm, {
                spaceBetween: 32,
                autoHeight: true,
                observeSlideChildren: true,
                allowTouchMove: false,
                slidesPerView: 'auto',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        return (
                            '<span class="relative cursor-pointer mr-2 bg-[#160C3A] px-5 pt-5 pb-4 text-md md:text-lg rounded-t-xl min-w-max after:absolute after:-bottom-1 after:left-0 after:right-0 after:h-3 after:w-full after:bg-gradient-to-t after:from-[#311591] after:to-transparent after:opacity-50 ' +
                            className +
                            '">' +
                            tabNames[index] +
                            '</span>'
                        );
                    },
                    bulletActiveClass: '!bg-purple-900 after:hidden'
                },
                on: {
                    slideChange: sw => {
                        const wrapper = sw.el.children[2];
                        const act = wrapper.children[0].querySelector(
                            'span:nth-child(' + (sw.snapIndex + 1) + ')'
                        );
                        wrapper.scrollTo({
                            left: act.getBoundingClientRect().left + wrapper.scrollLeft - 16,
                            behavior: 'smooth'
                        });
                        //sw.snapIndex
                    }
                }
            });

            let elements = document.getElementsByClassName('accordion-expand');

            const myFunction = function () {
                setTimeout(() => {
                    swiper.updateAutoHeight(100);
                }, 100);
            };

            for (let i = 0; i < elements.length; i++) {
                elements[i].addEventListener('click', myFunction, false);
            }
        });

        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                const elm = document.querySelector(this.getAttribute('href'));
                const offset = elm.getBoundingClientRect().top + window.scrollY;

                window.scrollTo({
                    top: offset - 100,
                    behavior: 'smooth'
                });
            });
        });
    }

    //document.body.style.opacity = '1';
    document.documentElement.classList.remove('no-js');

    document.addEventListener('alpine:init', () => {
        Alpine.data('dropdown', () => ({
            open: false,
            toggle() {
                if (this.open) {
                    return this.close();
                }

                this.$refs.button.focus();

                this.open = true;
            },
            close(focusAfter) {
                if (!this.open) return;

                this.open = false;

                focusAfter && focusAfter.focus();
            }
        }));
    });

    if ($(window).width() > 768) {
        setTimeout(() => {
            let $active = $('.customLink.active').closest('li');
            if ($active.length > 0) {
                gsap.to('#menu .menu-hover-line', 0.01, {
                    css: {
                        width: $active.outerWidth() + 'px',
                        left: $active.position().left + 'px',
                        opacity: 1
                    },
                    overwrite: true
                });
            }
        }, 200);
    }
    update();
    Alpine.start();
});
